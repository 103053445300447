<template>
	<main id="liste" v-cloak>
		<HeaderTab :title="$t('trigger.title_page')" :swiper_tabs="swiper_tabs"/>

		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div>
							<router-view></router-view>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>


<script type="text/javascript">

	export default {
		name: "Triggers",
		mixins: [],
		data () {
			return {
				param_tab: '',
				actual_tab: 'triggersList',
				swiper_tabs: [{
						id: 'liste',
						label: 'trigger.titre_liste',
						active: false,
						href : 'triggersList'
					}
				]
			}
		},

		mounted() {
			this.param_tab = this.$route.params.actual_tab
			this.init_component()
		},

		methods: {
			async init_component() {
				//
			}
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
